<template>
  <div class="short-video-player">
    <div class="playerW" v-if="liveList.length">
      <div class="playerWBanner">
        <div class="playBox" @click="toPlayer">
          <img class="livePlayIocn" src="../../assets/livePlayIocn.png" alt="" />
          <span class="livePlayText">点击进入直播间</span>
        </div>
        <div id="player">
          <!-- <video src="https://static.ppzx168.com.cn/62468206669cc.mp4"></video> -->
        </div>
        <div class="video-backgroundW">
          <canvas v-show="!imgSrc" id="video-background"></canvas>
          <img v-show="imgSrc" id="video-background" :style="`width:${w}${fullscreen ? '!important' : ''};height:${fullscreen ? '100%' : h
            }`" :src="imgSrc" alt="" />
        </div>
        <canvas id="myCanvas" :style="`width:${w}${fullscreen ? '!important' : ''};height:${fullscreen ? '100%' : h
          };object-fit: contain !important; opacity:0`"></canvas>
        <div class="myCanvasW" :style="`width:${w}${fullscreen ? '!important' : ''}`" v-show="imgSrc">
          <img id="myCanvas" :src="imgSrc" alt="" style="object-fit: contain !important" />
        </div>

        <div class="controls">
          <div class="controlsLeft">
            <img v-show="!imgSrc" class="livezanting" @click="setLive('paused')" src="../../assets/livezanting.png"
              alt="" />
            <img v-show="imgSrc" class="livezanting" @click="setLive('play')" src="../../assets/livebofang.png" alt="" />
            <img @click="videoRefresh" class="liveshuaxin" src="../../assets/liveshuaxin.png" alt="" />
          </div>
          <div class="controlsRight">
            <!-- <img
              class="livejingyin"
              src="../../assets/livejingyin.png"
              alt=""
            /> -->
            <img v-show="muted != 0" @click="shengyin(0)" class="livejingyin" style="width: 0.98958vw; height: 0.78125vw"
              src="../../assets/shengyinIcon.png" alt="" />
            <img v-show="muted == 0" @click="shengyin(50)" class="livejingyin" style="width: 0.98958vw; height: 0.78125vw"
              src="../../assets/livejingyin.png" alt="" />
          </div>
        </div>
      </div>

      <div class="playerWBannerNav">
        <div class="playerWBannerNavItem" v-for="item in liveList" :key="item.id" @click="activeLive(item)">
          <div class="itemBottomMantle">
            <div class="title nowrapEllipsis">
              {{ item.shop_live_log_info.title }}
            </div>
            <div class="itemInfo">
              <div class="itemInfoLeft">
                <img class="shopLogo" :src="item.thum" alt="" />
                <span class="shopName nowrapEllipsis">{{ item.name }}</span>
              </div>

              <div class="itemInfoRight">
                <img class="watchNumIcon" src="../../assets/watchNumIcon.png" alt="" />
                <span class="watchNum">{{
                  memberCount[item.live_log_uuid]
                }}</span>
              </div>
            </div>
          </div>
          <div class="itemMantle"></div>
          <img class="itemcover" :src="item.shop_live_log_info.thum" alt="" />
        </div>
      </div>
    </div>

    <!-- 为你推荐板块 -->
    <div class="recommendedW">
      <div class="recommended">
        <div class="recommendedTitle" v-show="list.length">
          <div class="title_en">Recommended</div>
          <div class="title_zh-cn">为你推荐</div>
        </div>

        <!-- <GoodsList :list="list"></GoodsList> -->
        <div class="recommendedGoodsList infinite-list">
          <div class="recommendedGoodsItem infinite-list-item" v-for="item in list" :key="item.id"
            @mouseenter="videoPlayback(item.live_log_uuid)" @mouseleave="videoStopped(item.live_log_uuid)"
            @click="toPlayer(item)">
            <div class="recommendedGoodsItemImg">
              <el-image :src="item.shop_live_log_info.thum" :lazy="true" fit="cover">
                <div slot="placeholder" class="image-slot">
                  <span class="el-icon-loading"></span>加载中...
                </div>
                <div slot="error" class="image-slot" style="color: #c0c4cc">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <div id="player2"></div>
            </div>
            <div class="recommendedGoodsItemInfo">
              <div class="name nowrapEllipsis">
                {{ item.shop_live_log_info.title }}
              </div>
              <div class="itemInfo">
                <div class="itemInfoLeft">
                  <img class="shopLogo" :src="item.thum" alt="" />
                  <span class="shopName nowrapEllipsis">{{ item.name }}</span>
                </div>
                <div class="itemInfoRight">
                  <!-- <img class="watchNumIcon" src="../../assets/watchNumIcon.png" alt="">
                                <span class="watchNum">9812</span> -->
                </div>
              </div>
              <!-- <div class="price">
                                <div class="presentPrice">
                                    ¥{{ item.sell_price }}
                                </div>
                                <div class="originalPrice">
                                    ¥{{ item.line_price }}
                                </div>

                                <div class="paid">
                                    {{ item.sell_num }}人已付款
                                </div>
                            </div> -->
            </div>
          </div>
        </div>
        <infinite-loading spinner="spiral" ref="infiniteLoading" @infinite="infiniteHandler" :distance="200"
          class="infinite-loading-wrap" style="color: #999999; font-size: 0.83333vw">
          <span slot="no-more">到底了亲～</span>
          <span slot="no-results">
            <el-empty description="暂无直播"></el-empty>
          </span>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraRTM from "agora-rtm-sdk";

import { shopsList } from "../../utils/api.js";

const AgoraClient = AgoraRTC.createClient({
  mode: "live", // 模式
  codec: "vp8", // 编码格式
  role: "audience", // 角色
});
const AgoraClient2 = AgoraRTC.createClient({
  mode: "live", // 模式
  codec: "vp8", // 编码格式
  role: "audience", // 角色
});
const rtc = {
  // 用来放置本地音视频频轨道对象。
  localAudioTrack: null,
  localVideoTrack: null,
};
const client = AgoraRTM.createInstance("84ff6fab3c894b70ba847e2bcd46b3fe");

var remoteAudioTrack = null;
var remoteVideoTrack = null;
export default {
  components: {},
  data() {
    return {
      options: {
        // 替换成你自己项目的 App ID。
        appId: "84ff6fab3c894b70ba847e2bcd46b3fe",
        // 传入目标频道名。
        channel: "",
        // 如果你的项目开启了 App 证书进行 Token 鉴权，这里填写生成的 Token 值。
        token: null,
        // 设置频道内的用户角色，可设为 "audience" 或 "host"
        role: "audience",
        uid:
          ("1" + `${+new Date()}`.substring(`${+new Date()}`.length - 8)) * 1,
      },
      options2: {
        // 替换成你自己项目的 App ID。
        appId: "84ff6fab3c894b70ba847e2bcd46b3fe",
        // 传入目标频道名。
        channel: "",
        // 如果你的项目开启了 App 证书进行 Token 鉴权，这里填写生成的 Token 值。
        token: null,
        // 设置频道内的用户角色，可设为 "audience" 或 "host"
        role: "audience",
        uid:
          ("2" + `${+new Date()}`.substring(`${+new Date()}`.length - 8)) * 1,
      },

      liveList: [],
      live_log_uuid: "",
      userUid: "",
      userUid2: "",
      page: {
        page_limit: 8,
        page_num: 1,
      },
      list: [],
      imgSrc: "",

      w: "100%",
      h: "100%",
      memberCount: "",
      fullscreen: true,
      memberCountTime: null,
      channelClient: null,
      muted: 50,
      channelIds: [],
      videouuid: "",
    };
  },
  created() {
    // createClient(AgoraClient)
    // 由于join是异步处理，所以我使用了async&await
    this.getLiveList();
  },
  mounted() {

  },
  beforeDestroy() {
    let user = this.userUid;
    AgoraClient.unsubscribe(user);
    AgoraClient.leave();
    this.channelClient.leave(this.options.channel);
    client.logout();
    clearInterval(this.memberCountTime);
  },

  watch: {},

  methods: {
    shengyin(num) {
      remoteAudioTrack.setVolume(num);
      this.muted = num;
    },
    videoRefresh() {
      let user = this.userUid;
      AgoraClient.unsubscribe(user);
      AgoraClient.leave();
      this.channelClient.leave(this.options.channel);
      clearInterval(this.memberCountTime);
      this.startBasicLive();
    },
    setLive(type) {
      if (type == "paused") {
        // var videoPlayer = document.getElementsByTagName("video")[0];
        // console.log(videoPlayer);
        // videoPlayer.pause()
        var v = document.querySelector("video");
        let canvas = document.getElementById("myCanvas");
        console.log(canvas);
        var ctx = canvas.getContext("2d");
        var cw = Math.floor(v.clientWidth);
        var ch = Math.floor(v.clientHeight);
        canvas.width = cw;
        canvas.height = ch;
        ctx.drawImage(v, 0, 0, cw, ch);
        var oGrayImg = canvas.toDataURL("image/jpeg");
        this.imgSrc = oGrayImg;
        remoteAudioTrack.setVolume(0);
      } else {
        this.imgSrc = "";
        remoteAudioTrack.setVolume(100);
      }
    },

    activeLive(item) {
      console.log(item.live_log_uuid);
      console.log(this.options.channel);
      if (this.options.channel != item.live_log_uuid) {
        let user = this.userUid;
        AgoraClient.unsubscribe(user);
        AgoraClient.leave();
        this.startBasicLive();
      }
    },
    videoPlayback(channel) {
      this.options2.channel = channel;
      this.startBasicLive2();
    },

    videoStopped() {
      let user = this.userUid2;
      AgoraClient2.unsubscribe(user);
      AgoraClient2.leave();
      // this.startBasicLive2()
    },

    toPlayer(item) {
      if (item.uuid) {
        this.$router.push({
          path: "livePlayer",
          query: { uuid: item.uuid },
        });
      } else {
        this.$router.push({
          path: "livePlayer",
          query: { uuid: this.videouuid },
        });
      }
    },

    //直播列表
    getLiveList() {
      shopsList({
        page: {
          page_limit: 3,
          page_num: 1,
        },
        map: {
          is_live: 1, //是否直播
        },
        full_fields: ["shop_live_log_info"],
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          if (res.data.length) {
            this.liveList = res.data;
            let channelIds = [];
            this.videouuid = res.data[0].uuid;
            client.login({
              uid: "1" + `${+new Date()}`.substring(`${+new Date()}`.length - 8),
            });
            res.data.forEach((item) => {
              channelIds.push(item.live_log_uuid);
            });
            this.channelIds = channelIds;

            this.startBasicLive();
          }
        }
      });
    },

    async startBasicLive() {
      let that = this;
      let options = this.options;
      if (!options.channel) {
        console.log(this.liveList);
        options.channel = this.liveList[0].live_log_uuid;
      }
      // 1.创建本地客户端
      // rtc.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
      // // 2.设置用户角色
      // // Role 的值可以是 "host" 或者 "audience"。
      // rtc.client.setClientRole(options.role, { level: 1 });
      //3.加入目标频道
      console.log(this.options.channel, "this.options.channel");
      console.log(options);
      const uid = await AgoraClient.join(
        options.appId,
        options.channel,
        options.token,
        Number(options.uid)
      );
      console.log(uid, "加入目标频道");
      // 5.订阅远端用户
      // console.log(AgoraClient.on("user-published"));
      AgoraClient.on("user-published", async (user, mediaType) => {
        console.log(user, mediaType, "订阅远端用户");
        that.userUid = user;

        // 开始订阅远端用户。
        await AgoraClient.subscribe(user, mediaType);
        console.log("subscribe success");
        // 订阅完成后，从 `user` 中获取远端音频轨道对象。
        remoteAudioTrack = user.audioTrack;
        remoteVideoTrack = user.videoTrack;

        // 表示本次订阅的是音频。
        if (mediaType === "video") {
          remoteVideoTrack.play("player");
          that.liveBg();
          // remoteVideoTrack.play('playerBg')
        } else {
          remoteAudioTrack.play();
        }
      });

      const channel = client.createChannel(options.channel);
      that.memberCountTime = setInterval(() => {
        client.getChannelMemberCount(that.channelIds).then((res) => {
          that.memberCount = res;
          console.log(that.memberCount);
        });
      }, 1000);

      channel.join();
      that.channelClient = channel;
      //   channel.on("ChannelMessage", function (messageObj, id) {
      //     console.log(id);
      //     let text = messageObj.text.split("  ");
      //     that.commentList.push({
      //       name: text[0],
      //       msg: text[1],
      //     });
      //     that.scrollToBottom();
      //   });
    },
    async startBasicLive2() {
      let that = this;
      let options = this.options2;
      // if (!options.channel) {
      //     console.log(this.liveList);
      //     options.channel = this.liveList[0].live_log_uuid
      // }
      // 1.创建本地客户端
      // rtc.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
      // // 2.设置用户角色
      // // Role 的值可以是 "host" 或者 "audience"。
      // rtc.client.setClientRole(options.role, { level: 1 });
      //3.加入目标频道
      console.log(this.options.channel, "this.options.channel");
      console.log(options);
      const uid = await AgoraClient2.join(
        options.appId,
        options.channel,
        options.token,
        Number(options.uid)
      );
      console.log(uid, "加入目标频道");
      // 5.订阅远端用户
      // console.log(AgoraClient2.on("user-published"));
      AgoraClient2.on("user-published", async (user, mediaType) => {
        console.log(user, mediaType, "订阅远端用户");
        that.userUid2 = user;

        // 开始订阅远端用户。
        await AgoraClient2.subscribe(user, mediaType);
        console.log("subscribe success");
        // 订阅完成后，从 `user` 中获取远端音频轨道对象。
        console.log();
        const remoteAudioTrack = user.audioTrack;
        const remoteVideoTrack = user.videoTrack;
        rtc.localAudioTrack = remoteAudioTrack;
        rtc.localVideoTrack = remoteVideoTrack;
        // 表示本次订阅的是音频。
        if (mediaType === "video") {
          remoteVideoTrack.play("player2");
          that.liveBg();
          // remoteVideoTrack.play('playerBg')
        } else {
          // remoteAudioTrack.play()
        }
      });
    },
    liveBg() {
      var v = document.getElementsByTagName("video")[0];
      var canvas = document.getElementById("video-background");
      console.log(canvas);
      var context = canvas.getContext("2d");
      var cw = Math.floor(canvas.clientWidth);
      var ch = Math.floor(canvas.clientHeight);
      document.getElementsByTagName("video")[0].play();
      canvas.width = cw;
      canvas.height = ch;
      draw(v, context, cw, ch);
      function draw(v, c, w, h) {
        c.drawImage(v, 0, 0, w, h);
        setTimeout(draw, 20, v, c, w, h);
      }
    },

    infiniteHandler($state) {
      shopsList({
        page: this.page,
        map: {
          is_live: 1, //是否直播
        },
        full_fields: ["shop_live_log_info"],
      }).then((res) => {
        if (res.data.length) {
          this.page.page_num++;
          this.list = this.list.concat(res.data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#player {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  //   margin-right: 20px;
  width: 1036px;
  height: 715px;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /deep/ div {
    background-color: transparent !important;
  }

  /deep/ video {
    object-fit: contain !important;
    width: auto !important;
    object-fit: contain !important;
    left: 50% !important;
    transform: translate(-50%, 0);

    &~div {
      background-color: transparent !important;
    }
  }
}

.myCanvasW {
  width: 100%;
  //   width: calc(100% - 3.33rem) !important ;
  position: absolute;
}

#myCanvas {
  position: absolute;
  left: 50% !important;
  transform: translate(-50%, 0);
  z-index: 10;
}

#player2 {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  width: 314px;
  height: 218px;
  border-radius: 10px;
}

.video-backgroundW {
  position: absolute;
  width: 1036px;
  height: 715px;
  overflow: hidden;
}

#video-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  filter: blur(50px);
}

#playerBg {
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 20px;
  width: 1036px;
  height: 715px;
  background: #000;
  border-radius: 10px;

  /deep/ video {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
}

.playerW {
  display: flex;
  width: calc(1440px - 68px);
  margin: 0 auto;
  height: 715px;
  padding: 0 34px;

  .playerWBanner {
    position: relative;
    width: 100%;

    .controls {
      background-image: linear-gradient(180deg,
          transparent,
          rgba(0, 0, 0, 0.5));
      height: 0px;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      z-index: 12;
      width: calc(1036px - 60px);
      padding: 0 30px;
      position: absolute;
      bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .controlsLeft {
        display: flex;
        align-items: center;

        .livezanting {
          cursor: pointer;
          width: 15px;
          height: 23px;
          margin-right: 35px;
        }

        .liveshuaxin {
          cursor: pointer;
          width: 24px;
          height: 23px;
        }
      }

      .controlsRight {
        display: flex;

        .livejingyin {
          cursor: pointer;
          width: 29px;
          height: 23px;
        }
      }
    }

    &:hover .playBox {
      display: flex;
      z-index: 99;
    }

    &:hover .controls {
      height: 80px;
      opacity: 1;
    }

    .playBox {
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 337px;
      height: 81px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 41px;
      display: none;
      align-items: center;
      justify-content: center;
      z-index: 9;

      &:hover {
        border: 1px solid #ffffff;
      }

      .livePlayIocn {
        width: 29px;
        height: 22px;
        margin-right: 16px;
      }

      .livePlayText {
        font-size: 24px;
        color: #ffffff;
      }
    }
  }

  .playerWBannerNav {
    display: flex;
    flex-direction: column;
    padding: 11px 0;
    margin-left: 20px;

    .playerWBannerNavItem {
      cursor: pointer;
      position: relative;
      display: flex;
      margin-bottom: 20px;

      &:nth-child(3n) {
        margin-bottom: 0;
      }

      &:hover .itemMantle {
        background: none;
      }

      &:hover .itemBottomMantle {
        height: 70px;

        .itemInfo {
          display: flex;
        }

        .title {
          line-height: 1.15;
        }
      }

      .itemMantle {
        position: absolute;
        left: 0;
        top: 0;
        width: 314px;
        height: 218px;
        background: rgba(0, 0, 0, 0.5);
      }

      .itemcover {
        width: 314px;
        height: 218px;
        object-fit: cover;
      }

      .itemBottomMantle {
        padding: 0 18px;
        position: absolute;
        bottom: 0;
        width: calc(314px - 36px);
        height: 70px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        border-radius: 5px;

        .title {
          line-height: 70px;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          letter-spacing: 2px;
          margin-bottom: 9px;
        }

        .itemInfo {
          display: none;
          align-items: center;
          justify-content: space-between;

          .itemInfoLeft {
            display: flex;
            align-items: center;

            .shopLogo {
              width: 24px;
              height: 24px;
              margin-right: 6px;
            }

            .shopName {
              display: inline-block;
              width: 160px;
              font-size: 13px;
              color: #ffffff;
            }
          }

          .itemInfoRight {
            display: flex;
            align-items: center;

            .watchNumIcon {
              width: 12px;
              height: 12px;
              margin-right: 6px;
            }

            .watchNum {
              font-size: 13px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.recommendedW {
  // background: rgba(238, 238, 238, 0.3);

  .recommended {
    margin: auto;
    width: 985px;
    padding: 100px 210px;

    .recommendedTitle {
      margin-bottom: 50px;

      .title_en {
        text-align: center;
        margin-bottom: 6px;
        font-size: 26px;
        font-weight: 600;
        color: #666666;
        letter-spacing: 10px;
      }

      .title_zh-cn {
        text-align: center;
        font-size: 26px;
        font-weight: 900;
        color: #ff2442;
        letter-spacing: 13px;
      }
    }
  }
}

.recommendedGoodsList {
  display: flex;
  flex-wrap: wrap;

  .recommendedGoodsItem {
    position: relative;
    cursor: pointer;
    margin-right: 24px;
    margin-bottom: 60px;
    width: 311px;
    height: 308px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(230, 230, 230, 0.5);
    border-radius: 4px;
    transition: all 0.2s;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover {
      margin-top: -5px;
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      transform: translate3d(0, -2px, 0);
    }

    &:hover .recommendedGoodsItemMask {
      display: block;
    }

    .recommendedGoodsItemImg {
      position: relative;
      width: 311px;
      height: 218px;

      .el-image {
        width: 311px;
        height: 218px;
      }
    }

    .recommendedGoodsItemInfo {
      padding: 20px 10px 0 10px;

      .name {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }

      .itemInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .itemInfoLeft {
          display: flex;
          align-items: center;

          .shopLogo {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin-right: 6px;
          }

          .shopName {
            display: inline-block;
            width: 200px;
            font-size: 13px;
            color: #666666;
          }
        }

        .itemInfoRight {
          display: flex;
          align-items: center;

          .watchNumIcon {
            width: 12px;
            height: 12px;
            margin-right: 6px;
          }

          .watchNum {
            font-size: 13px;
            color: #ffffff;
          }
        }
      }
    }

    .recommendedGoodsItemMask {
      text-align: center;
      position: absolute;
      top: 0;
      width: 311px;
      height: 408px;
      background: rgba(0, 0, 0, 0.5);
      display: none;

      .goodsQR {
        width: 232px;
        height: 232px;
        margin-top: 63px;
        margin-bottom: 21px;
      }

      .goodsQRText {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
</style>